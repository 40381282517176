import './App.css';
import React from 'react';
import { chart, defaultOptions, dateFormat } from 'highcharts';
import { get } from 'superagent';
import Form from './Form';

// About chart data:
// 'data' is an array of arrays containing two numbers
// first number should refer to the date.
// second number should refer to the price of ETH.
class LooksRare extends React.Component {
  constructor(props) {
    super(props);
    this.apiData = []
    // data: [[161.2, 51.6], [167.5, 59.0], ...]
    this.state = {
        series: [{
            name: 'High Rank',
            color: 'rgba(223, 83, 83, .5)',
            data: []   
        }, {
            name: 'Low Rank',
            color: 'rgba(119, 152, 191, .5)',
            data: []
        }]
    }
}

handleSubmit = address => {  
    this.setLooksRareSales(address.address)
}

highChartsRender() {
    chart('highcharts-container', {
        chart: {
            type: 'scatter',
            zoomType: 'xy'
        },
        title: {
            text: ''
        },
        subtitle: {
            text: 'NFT Trades for: ' + this.state.collectionName
        },
        xAxis: {
            title: {
                enabled: true,
                text: 'Date/Time'
            },
            startOnTick: true,
            endOnTick: true,
            showLastLabel: true,
            type: 'datetime',
            dateTimeLabelFormats: {
                minute: '%H:%M',
                day: '%b %e',
	            week: '%b %e',
            },
            //pointStart: this.state.firstSellDate,
            // pointInterval: 24 * 3600 * 1000
        },
        yAxis: {
            title: {
                text: 'Price in ETHE'
            }
        },
        legend: {
            layout: 'vertical',
            align: 'left',
            verticalAlign: 'top',
            x: 100,
            y: 70,
            floating: true,
            backgroundColor: defaultOptions.chart.backgroundColor,
            borderWidth: 1
        },
        tooltip: {
            formatter: function() {
                console.log(new Date(this.x))
                return  '<b>' + this.series.name +'</b><br/>' +
                    dateFormat('%b %e %Y', new Date(this.x)) +', ' +this.y + ' ETHE';
            }
        },
        plotOptions: {
            scatter: {
                marker: {
                    radius: 5,
                    states: {
                        hover: {
                            enabled: true,
                            lineColor: 'rgb(100,100,100)'
                        }
                    }
                },
                states: {
                    hover: {
                        marker: {
                            enabled: false
                        }
                    }
                },
                tooltip: {
                    headerFormat: '<b>{series.name}</b><br>',
                    // pointFormat: '{point.x} Date, {point.y} ETH',
                }
            }
        },
        series: this.state.series
    });
}

setLooksRareSales(collectionAddress) {
    const url = 'https://api.looksrare.org/api/v1/events'
    get(url)
    .query({ type: 'SALE', collection: collectionAddress })
    .end((err, res) => {
        if (err) { return console.log(err) }
        console.log('Request successfull')
        // TODO: Split below logic into separate 'service' class
        /*
            Instantiates new empty master array
            for each object in res.body.data array:
                initialize new array
                get order.endTime and add to array as 1st value
                get order.price and add to array as 2nd value
                Add new array to master array 
        */
        const highRankDataSeries = []
        const lowRankDataSeries = []
        var priceInEther;
        res.body.data?.forEach(nftSale => {
            priceInEther = (Number(BigInt(nftSale.order.price))/Number(1000000000000000000n))

            // In replacement of mechanism for determining 'rating', as that will be a separate, larger effort.
            const randomInt = Math.round(Math.random() * 100)            
            if (randomInt % 2 === 0) {
                // if integer is even...
                highRankDataSeries.push([nftSale.order.endTime*1000, priceInEther])
            } else {
                // if integer is odd...
                lowRankDataSeries.push([nftSale.order.endTime*1000, priceInEther])
            }
        });

        const firstSellDate = res.body.data.reverse()[0].order.endTime

        this.state = {
            series: [{
                name: 'HIGH Rank',
                color: 'rgba(223, 83, 83, .5)',
                data: highRankDataSeries    
            }, {
                name: 'Low Rank',
                color: 'rgba(119, 152, 191, .5)',
                data: lowRankDataSeries
            }],
            collectionName: res.body.data[0].collection.name ? res.body.data[0].collection.name : 'Untitled',
            firstSellDate
        }
        this.highChartsRender();
    })
}

// Functions in here must all return void
componentDidMount() {
    // const collectionAddress = ''
    // this.setLooksRareSales(collectionAddress)    
    
}

render() {
    return (
    <div>
        <br></br>
        <h2>LooksRare Trades</h2>      
        <Form handleSubmit={this.handleSubmit} />
        <br></br>        
        <div id="highcharts-container" className="chart-display"></div>
    </div>
    );
}
}

export default LooksRare;
