import React, {Component} from 'react';

class Form extends Component {
    constructor(props) {
        super(props);
        
        this.initialState = {
            address: '',
        };

        this.state = this.initialState;
    }

    handleChange = event => {
        console.log('handling change')
        const { name, value } = event.target;

        this.setState({
            [name] : value
        });
    }

    onFormSubmit = (event) => {
        console.log('submitting address')
        event.preventDefault();
        
        this.props.handleSubmit(this.state);
    }

    render() {
        const { address } = this.state; 

        return (
            <form onSubmit={this.onFormSubmit}>
                <label forhtml="address">Collection Address </label>
                <input 
                    type="text" 
                    name="address" 
                    id="address"
                    value={address} 
                    onChange={this.handleChange} />
                <button type="submit" className="submit-button">
                    Submit
                </button>
            </form>
        );
    }
}

export default Form;