// TODO: integrate OpenSea data (below is copy of LooksRare.js) -> need API key for this

import './App.css';
import React from 'react';
// import Highcharts from 'highcharts';
// import { get } from 'superagent';
import Form from './Form';

// About chart data:
// 'data' is an array of arrays containing two numbers
// first number should refer to the date.
// second number should refer to the price of ETH.
class OpenSea extends React.Component {
  constructor(props) {
    super(props);
    this.apiData = []
    // data: [[161.2, 51.6], [167.5, 59.0], ...]
    this.state = {
        series: [{
            name: 'High Rank',
            color: 'rgba(223, 83, 83, .5)',
            data: []   
        }, {
            name: 'Low Rank',
            color: 'rgba(119, 152, 191, .5)',
            data: []
        }]
    }
}

handleSubmit = address => {  
    this.setLooksRareSales(address.address)
}

render() {
    return (
    <div>
        <br></br>
        <h2>OpenSea Trades</h2>        
        <Form handleSubmit={this.handleSubmit} />
        <br></br>        
        <div id="highcharts-container" className="chart-display"></div>
    </div>
    );
}
}

export default OpenSea;
