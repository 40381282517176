import React from 'react';
import LooksRare from './LooksRare';
import OpenSea from './OpenSea';
import X2Y2 from './X2Y2';

class App extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            selectedTab: ''
        }
    }

    setTab = (tab) => {
        this.setState({selectedTab: tab})
    }

    selectedTab = () => {
        switch(this.state.selectedTab){
            case 'Looks Rare':
                return <LooksRare />
            case 'Open Sea':
                return <OpenSea />
            case 'x2y2':
                return <X2Y2 />
            default:
                return <h3>Select Marketplace</h3>
        }
    }

    render() {
        return(
            
            <div id="container" className='page-container'>
                <h1>NFT Tool - Highcharts PoC</h1>
                <div>
                    <button onClick={() => this.setTab('Looks Rare')}>Looks Rare</button>
                    <button onClick={() => this.setTab('Open Sea')}>Open Sea</button>
                    <button onClick={() => this.setTab('x2y2')}>x2y2</button>
                </div>
                {this.selectedTab()}
            </div>
        )
    }

    // render() {
    //     return(
    //         <h1>hello</h1>
    //     )
    // }
}

export default App;